import React from 'react';

export const SearchIcon = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.9917 15.395L9.61042 10.0138C9.18333 10.3554 8.69219 10.6259 8.13698 10.8252C7.58177 11.0245 6.99097 11.1242 6.36458 11.1242C4.81285 11.1242 3.49971 10.5866 2.42517 9.51152C1.35063 8.43641 0.81307 7.12327 0.8125 5.5721C0.811931 4.02094 1.34949 2.7078 2.42517 1.63269C3.50085 0.557575 4.81399 0.0200195 6.36458 0.0200195C7.91518 0.0200195 9.22861 0.557575 10.3049 1.63269C11.3811 2.7078 11.9184 4.02094 11.9167 5.5721C11.9167 6.19849 11.817 6.78929 11.6177 7.3445C11.4184 7.89971 11.1479 8.39085 10.8063 8.81794L16.1875 14.1992L14.9917 15.395ZM6.36458 9.41585C7.43229 9.41585 8.33999 9.0423 9.08767 8.29519C9.83535 7.54808 10.2089 6.64038 10.2083 5.5721C10.2078 4.50383 9.83421 3.59642 9.08767 2.84987C8.34113 2.10333 7.43343 1.72949 6.36458 1.72835C5.29574 1.72721 4.38833 2.10105 3.64235 2.84987C2.89638 3.59869 2.52254 4.5061 2.52083 5.5721C2.51913 6.6381 2.89297 7.5458 3.64235 8.29519C4.39174 9.04457 5.29915 9.41813 6.36458 9.41585Z"
        fill="white"
      />
    </svg>
  );
};
