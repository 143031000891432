import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../util/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

import { Button, OutsideClickHandler } from '..';
import DateRangeController from '../FieldDateRangeController/DateRangeController';
import LocationAutocompleteInputImpl from '../LocationAutocompleteInput/LocationAutocompleteInputImpl';
import { createSearchParams } from './helpers';

import { SearchIcon } from './SearchIcon';
import css from './SearchFormHero.module.css';
import { END_DATE, START_DATE } from '../../util/dates';
import classNames from 'classnames';
import constructionSiteImg from './assets/construction-site-illustration.png';
import criticalInfrastructureImg from './assets/critical-infrastructure-illustration.png';
import solarParksImg from './assets/solar-parks-illustration.png';
import windFarmsImg from './assets/wind-farms-illustration.png';
import flagDE from './assets/de.svg';
import flagAT from './assets/at.svg';
import flagCH from './assets/ch.svg';

const IconArrowPrev = ({ isDisabled }) => {
  if (isDisabled) return <></>;
  return (
    <svg
      class="DateRangeController_arrowIcon__O6s1B"
      width="9"
      height="13"
      viewBox="0 0 9 13"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#ff0953"
    >
      <path
        d="M1.195 6.03c-.26.26-.26.68 0 .94l5.333 5.335c.262.26.683.26.943 0 .262-.26.262-.683 0-.943L2.61 6.5l4.86-4.862c.262-.26.262-.683 0-.943-.26-.26-.68-.26-.942 0L1.195 6.03z"
        fill-rule="evenodd"
        fill="#ff0953"
      ></path>
    </svg>
  );
};

const KeywordAutocompletePanel = ({ placeHolderId, onChange, value, ...rest }) => {
  const intl = useIntl();

  const handleChange = e => {
    onChange(e.target.value);
  };

  return (
    <input
      className={css.input}
      value={value}
      onChange={handleChange}
      type="text"
      placeholder={intl.formatMessage({
        id: placeHolderId ? placeHolderId : 'SearchFormHero.keywords.placeholder',
      })}
      autoComplete="off"
      {...rest}
    />
  );
};

const LocationAutocompletePanel = ({ onChange, autoFocus, value }) => {
  const intl = useIntl();

  return (
    <div className={css.locationAutocompletePanel}>
      <LocationAutocompleteInputImpl
        className={css.locationAutocomplete}
        iconClassName={css.locationAutocompleteIcon}
        inputClassName={css.locationAutocompleteInput}
        predictionsClassName={css.locationAutocompletePredict}
        autoFocus={autoFocus}
        placeholder={intl.formatMessage({ id: 'SearchFormHero.where.placeholder' })}
        input={{
          value: value,
          name: 'location',
          onChange,
          onFocus: () => null,
          onBlur: () => null,
        }}
      />
    </div>
  );
};

const SearchBox = ({
  isOpenAreaPanel,
  setIsOpenAreaPanel,
  isSmallScreen,
  intl,
  handleSetKeyword,
  keywordsValue,
  setKeywordsValue,
  setAreaValue,
  handleOpenDatePanel,
  startDateInputRef,
  dateLabel,
  handleChangeDateInput,
  handleResetDates,
  isOpenPanel,
  endDateInputRef,
  focusedInput,
  handleChangeFocusInput,
  handleSelectDates,
  whenValue,
  setIsOpenPanel,
  handlePrevMonthClick,
  handleNextMonthClick,
  isPrevDisabled,
  isMobile,
  numberOfMonths,
  isOutsideRange,
  currentMonth,
  handleLoadMoreMonths,
  isOpenLocationPanel,
  setIsLocationPanel,
  countryLabel,
  setCountryLabel,
  setCountryValue,
  handleSubmit,
}) => (
  <div className={css.searchBox}>
    <div className={css.searchBoxItem}>
      <OutsideClickHandler
        className={css.searchBoxItemOutsideClick}
        onOutsideClick={() => {
          if (isOpenAreaPanel) {
            setIsOpenAreaPanel(false);
          }
        }}
      >
        <div className={css.searchBoxItemContent}>
          {!isSmallScreen && (
            <label>{intl.formatMessage({ id: 'SearchFormHero.keywords.label' })}</label>
          )}
          <KeywordAutocompletePanel
            onFocus={() => setIsOpenAreaPanel(true)}
            onChange={handleSetKeyword}
            value={keywordsValue}
            style={{ paddingRight: '10px' }}
            placeHolderId={isSmallScreen ? 'SearchFormHero.keywords.label' : undefined}
          />
          {keywordsValue ? (
            <FontAwesomeIcon
              className={css.keywordCancelIcon}
              color="#C3C3C3"
              icon={faClose}
              onClick={() => {
                setKeywordsValue('');
                setAreaValue(null);
              }}
            />
          ) : (
            <></>
          )}
          {isOpenAreaPanel && (
            <div className={css.areaSearchDropdown}>
              <h3 className={css.areaSearchHeading}>
                <FormattedMessage id="SearchFormTopbar.area.heading" />
              </h3>
              <div className={css.areaSearchSegment}>
                {[
                  {
                    textId: 'SearchFormTopbar.area.constructionSite',
                    img: constructionSiteImg,
                    imgAlt: 'construction-site',
                    area: 'constructionsites',
                  },
                  {
                    textId: 'SearchFormTopbar.area.criticalInfrastructure',
                    img: criticalInfrastructureImg,
                    imgAlt: 'critical-infrastructure',
                    area: 'criticalinfrastructures',
                  },
                  {
                    textId: 'SearchFormTopbar.area.solarparks',
                    img: solarParksImg,
                    imgAlt: 'solar-parks',
                    area: 'solarparks',
                  },
                  {
                    textId: 'SearchFormTopbar.area.windFarms',
                    img: windFarmsImg,
                    imgAlt: 'wind-farms',
                    area: 'windfarms',
                  },
                ].map(({ textId, img, imgAlt, area }) => (
                  <div
                    key={area}
                    className={css.areaSearchCard}
                    onClick={() => {
                      setKeywordsValue(intl.formatMessage({ id: textId }));
                      setAreaValue(area);
                      setIsOpenAreaPanel(false);
                    }}
                  >
                    <div className={css.areaSearchImgWrapper}>
                      <img className={css.areaSearchImg} src={img} alt={imgAlt} />
                    </div>
                    <div className={css.areaSearchText}>
                      <FormattedMessage id={textId} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </OutsideClickHandler>
    </div>
    <div className={css.searchBoxItem}>
      <div className={css.searchBoxItemContent} onClick={handleOpenDatePanel(1, START_DATE)}>
        {!isSmallScreen && (
          <label>{intl.formatMessage({ id: 'SearchFormHero.when.start.label' })}</label>
        )}
        <div className={css.searchBoxItemInput}>
          <div className={css.dateInputWrapper}>
            <input
              ref={startDateInputRef}
              className={classNames(css.input, css.dateInput)}
              value={dateLabel.startDateLabel || ''}
              onChange={handleChangeDateInput(START_DATE)}
              placeholder={intl.formatMessage({
                id: isSmallScreen
                  ? 'SearchFormHero.when.start.label'
                  : 'SearchFormHero.when.start.placeholder',
              })}
              readOnly
            />
            {dateLabel.endDateLabel ? (
              <FontAwesomeIcon
                className={css.dateCancelIcon}
                color="#C3C3C3"
                icon={faClose}
                onClick={handleResetDates}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
    <div className={css.searchBoxItem}>
      <OutsideClickHandler
        className={css.searchBoxItemOutsideClick}
        onOutsideClick={() => {
          if (isOpenPanel === 1) {
            setIsOpenPanel(false);
          }
        }}
      >
        <div className={css.searchBoxItemContent} onClick={handleOpenDatePanel(1, END_DATE)}>
          {!isSmallScreen && (
            <label>{intl.formatMessage({ id: 'SearchFormHero.when.end.label' })}</label>
          )}
          <div className={css.searchBoxItemInput}>
            <div className={css.dateInputWrapper}>
              <input
                ref={endDateInputRef}
                className={classNames(css.input, css.dateInput)}
                value={dateLabel.endDateLabel || ''}
                onChange={handleChangeDateInput(END_DATE)}
                placeholder={intl.formatMessage({
                  id: isSmallScreen
                    ? 'SearchFormHero.when.end.label'
                    : 'SearchFormHero.when.end.placeholder',
                })}
                readOnly
              />
              {dateLabel.endDateLabel ? (
                <FontAwesomeIcon
                  className={css.dateCancelIcon}
                  color="#C3C3C3"
                  icon={faClose}
                  onClick={handleResetDates}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        {isOpenPanel === 1 && (
          <SelectDatePanel
            focusedInput={focusedInput}
            onFocusChange={handleChangeFocusInput}
            onSelect={handleSelectDates}
            startDate={whenValue?.startDate}
            endDate={whenValue?.endDate}
            onReset={handleResetDates}
            onClose={() => setIsOpenPanel(null)}
            minDate={moment(new Date())}
            onPrevMonthClick={handlePrevMonthClick}
            onNextMonthClick={handleNextMonthClick}
            navPrev={<IconArrowPrev isDisabled={isPrevDisabled} />}
            numberOfMonths={isMobile ? numberOfMonths : 2}
            orientation={isMobile ? 'verticalScrollable' : 'horizontal'} // Stack months vertically
            hideKeyboardShortcutsPanel={true} // Hide keyboard shortcuts for mobile
            isOutsideRange={isOutsideRange}
            initialVisibleMonth={() => currentMonth}
            onScroll={e => {
              const { scrollTop, clientHeight, scrollHeight } = e.target;
              if (scrollTop + clientHeight >= scrollHeight - 250) {
                handleLoadMoreMonths();
              }
            }}
          />
        )}
      </OutsideClickHandler>
    </div>
    <div className={css.searchBoxItem}>
      <OutsideClickHandler
        className={css.searchBoxItemOutsideClick}
        onOutsideClick={() => {
          if (isOpenLocationPanel) {
            setIsLocationPanel(false);
          }
        }}
      >
        <div className={css.searchBoxItemContent}>
          {!isSmallScreen && (
            <label>{intl.formatMessage({ id: 'SearchFormHero.where.label' })}</label>
          )}
          <KeywordAutocompletePanel
            onFocus={() => setIsLocationPanel(true)}
            value={countryLabel}
            readOnly
            style={{ paddingRight: '10px' }}
            placeHolderId={
              isSmallScreen ? 'SearchFormHero.where.label' : 'SearchFormHero.country.placeholder'
            }
          />
          {countryLabel ? (
            <FontAwesomeIcon
              className={css.CountryCancelIcon}
              color="#C3C3C3"
              icon={faClose}
              onClick={() => {
                setCountryLabel('');
                setCountryValue(null);
              }}
            />
          ) : (
            <></>
          )}{' '}
          {isOpenLocationPanel && (
            <div className={classNames(css.areaSearchDropdown, css.countrySearchDropdown)}>
              {/*                   <h3 className={css.areaSearchHeading}>
                  <FormattedMessage id="SearchFormTopbar.area.heading" />
                </h3> */}
              <div>
                {[
                  {
                    textId: 'SearchFormTopbar.where.de',
                    img: flagDE,
                    imgAlt: 'deliver-germany',
                    country: 'DE',
                  },
                  {
                    textId: 'SearchFormTopbar.where.at',
                    img: flagAT,
                    imgAlt: 'deliver-austria',
                    country: 'AT',
                  },
                  {
                    textId: 'SearchFormTopbar.where.ch',
                    img: flagCH,
                    imgAlt: 'deliver-swiss',
                    country: 'CH',
                  },
                ].map(({ img, imgAlt, textId, country }) => (
                  <div
                    key={country}
                    className={css.countryItem}
                    onClick={() => {
                      setCountryLabel(intl.formatMessage({ id: textId }));
                      setCountryValue(country);
                      setIsLocationPanel(false);
                    }}
                  >
                    <div className={css.countryImgWrapper}>
                      <img className={css.countryImg} src={img} alt={imgAlt} />
                    </div>

                    <FormattedMessage id={textId} />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </OutsideClickHandler>

      {/*    <div className={css.searchBoxItemContent}>
          <label>{intl.formatMessage({ id: 'SearchFormHero.where.label' })}</label>
          <LocationAutocompletePanel onChange={handleSetPrediction} value={whereValue} />
        </div> */}
    </div>

    <Button className={css.searchSubmit} onClick={handleSubmit}>
      <span>{intl.formatMessage({ id: 'SearchFormHero.submit' })}</span>
      <SearchIcon />
    </Button>
  </div>
);

const SelectDatePanel = ({
  onSelect,
  startDate,
  endDate,
  focusedInput,
  onFocusChange,
  onReset,
  onClose,
  onScroll,
  ...restProps
}) => {
  const checkIsOutsideRange = day => {
    return moment(day).diff(moment().set({ h: 0, m: 0, s: 0 }), 'minute') < 0;
  };
  return (
    <div className={css.selectDatePanel} onScroll={onScroll}>
      <DateRangeController
        focusedInput={focusedInput}
        onFocusChange={onFocusChange}
        onChange={() => null}
        onSingleDateChange={onSelect}
        value={{ startDate, endDate }}
        isOutsideRange={checkIsOutsideRange}
        numberOfMonths={2}
        {...restProps}
      />
    </div>
  );
};

const SearchFormHero = () => {
  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();

  const [keywordsValue, setKeywordsValue] = useState('');
  const [areaValue, setAreaValue] = useState(null);
  const [whereValue, setWhereValue] = useState(null);
  const [countryValue, setCountryValue] = useState(null);
  const [countryLabel, setCountryLabel] = useState('');
  const [whenValue, setWhenValue] = useState(null);
  const [isOpenPanel, setIsOpenPanel] = useState(false);
  const [focusedInput, setFocusedInput] = useState(START_DATE);
  const [dateLabel, setDateLabel] = useState({ startDateLabel: '', endDateLabel: '' });
  const startDateInputRef = useRef();
  const endDateInputRef = useRef();
  const [currentMonth, setCurrentMonth] = useState(moment(whenValue?.startDate ?? undefined));
  const [isPrevDisabled, setIsPrevMonthDisabled] = useState(true);
  const [numberOfMonths, setNumberOfMonths] = useState(2);
  const [isMobile, setIsMobile] = useState(
    typeof window !== 'undefined' && window?.innerWidth <= 920
  );
  const [isSmallScreen, setIsSmallScreen] = useState(
    typeof window !== 'undefined' && window?.innerWidth <= 1024
  );
  const [isOpenAreaPanel, setIsOpenAreaPanel] = useState(false);
  const [isOpenLocationPanel, setIsLocationPanel] = useState(false);
  const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);

  useEffect(() => {
    setIsPrevMonthDisabled(currentMonth.isSame(moment(), 'month'));
  }, [currentMonth]);

  useEffect(() => {
    if (isOpenPanel === 1) {
      setCurrentMonth(moment(whenValue?.startDate ?? undefined));
    }
  }, [isOpenPanel]);

  const handleNextMonthClick = () => {
    setCurrentMonth(currentMonth.clone().add(1, 'month'));
  };

  const handlePrevMonthClick = () => {
    setCurrentMonth(currentMonth.clone().subtract(1, 'month'));
  };

  const handleOpenDatePanel = (index, focusedInput) => () => {
    setFocusedInput(focusedInput);
    setIsOpenPanel(index);
  };

  const handleSetKeyword = value => {
    setKeywordsValue(value);
  };

  const handleSetCountry = value => {
    setCountryLabel(value);

    if (value == null) {
      setCountryValue(null);
    }
  };

  const handleSetPrediction = value => {
    const isCurrentLocation =
      !value?.search && !value?.selectedPlace?.address && !!value?.selectedPlace?.bounds;
    setWhereValue({
      ...value,
      ...(value?.name === 'location' && !!value?.onChange
        ? {
            search: intl.formatMessage({ id: 'SearchFormHero.where.loadingCurrentLocation' }),
            selectedPlace: {
              address: intl.formatMessage({ id: 'SearchFormHero.where.loadingCurrentLocation' }),
            },
          }
        : {}),
      ...(isCurrentLocation
        ? {
            search: intl.formatMessage({ id: 'SearchFormHero.where.currentLocation' }),
            selectedPlace: {
              ...(value?.selectedPlace || {}),
              address: intl.formatMessage({ id: 'SearchFormHero.where.currentLocation' }),
            },
          }
        : {}),
    });
  };

  const handleSelectDates = ({ startDate, endDate }) => {
    setWhenValue({
      startDate,
      endDate,
    });
    const startDateLabel = startDate ? moment(startDate).format('MMM DD.') : null;
    const endDateLabel = endDate ? moment(endDate).format('MMM DD.') : null;
    setDateLabel({
      ...dateLabel,
      ...(startDateLabel ? { startDateLabel } : {}),
      ...(endDateLabel ? { endDateLabel } : {}),
    });
    // setIsOpenPanel(null);
  };

  const handleChangeDateInput = dateType => e => {
    setDateLabel({
      ...dateLabel,
      ...(dateType === START_DATE ? { startDateLabel: e.target.value } : {}),
      ...(dateType === END_DATE ? { endDateLabel: e.target.value } : {}),
    });
  };

  const handleChangeFocusInput = focusedInput => {
    if (focusedInput === START_DATE) {
      startDateInputRef.current.focus();
    }
    if (focusedInput === END_DATE) {
      endDateInputRef.current.focus();
    }
    setFocusedInput(focusedInput);
  };

  const handleResetDates = e => {
    e?.stopPropagation?.();
    setWhenValue(null);
    setDateLabel({ startDateLabel: '', endDateLabel: '' });
  };

  const handleSubmit = () => {
    const searchParams = createSearchParams({
      whereValue,
      whenValue,
      keywordsValue,
      areaValue,
      countryValue,
    });
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
  };

  const handleLoadMoreMonths = () => {
    setNumberOfMonths(prev => prev + 2);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        const mobileView = window.innerWidth <= 920;
        const smallView = window.innerWidth < 1024;
        setIsMobile(mobileView);
        setIsSmallScreen(smallView);
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  const isOutsideRange = day => {
    const minimumDate = moment();
    return day.isBefore(minimumDate, 'day');
  };

  return (
    <div className={css.root}>
      {isSmallScreen ? (
        <>
          {isMobileSearchOpen && (
            <div className={css.searchBoxWrapper}>
              <FontAwesomeIcon
                onClick={() => {
                  setIsMobileSearchOpen(false);
                }}
                icon={faClose}
                className={css.searchBoxMobileCloseIcon}
              />
              <SearchBox
                isOpenAreaPanel={isOpenAreaPanel}
                setIsOpenAreaPanel={setIsOpenAreaPanel}
                isSmallScreen={isSmallScreen}
                intl={intl}
                handleSetKeyword={handleSetKeyword}
                keywordsValue={keywordsValue}
                setKeywordsValue={setKeywordsValue}
                setAreaValue={setAreaValue}
                handleOpenDatePanel={handleOpenDatePanel}
                startDateInputRef={startDateInputRef}
                dateLabel={dateLabel}
                handleChangeDateInput={handleChangeDateInput}
                handleResetDates={handleResetDates}
                isOpenPanel={isOpenPanel}
                endDateInputRef={endDateInputRef}
                focusedInput={focusedInput}
                handleChangeFocusInput={handleChangeFocusInput}
                handleSelectDates={handleSelectDates}
                whenValue={whenValue}
                setIsOpenPanel={setIsOpenPanel}
                handlePrevMonthClick={handlePrevMonthClick}
                handleNextMonthClick={handleNextMonthClick}
                isPrevDisabled={isPrevDisabled}
                isMobile={isMobile}
                numberOfMonths={numberOfMonths}
                isOutsideRange={isOutsideRange}
                currentMonth={currentMonth}
                handleLoadMoreMonths={handleLoadMoreMonths}
                isOpenLocationPanel={isOpenLocationPanel}
                setIsLocationPanel={setIsLocationPanel}
                countryLabel={countryLabel}
                setCountryLabel={setCountryLabel}
                setCountryValue={setCountryValue}
                handleSubmit={handleSubmit}
              />
            </div>
          )}
          <div className={css.searchMobileFieldWrapper}>
            <svg
              className={css.searchMobileFieldIcon}
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.9917 15.395L9.61042 10.0138C9.18333 10.3554 8.69219 10.6259 8.13698 10.8252C7.58177 11.0245 6.99097 11.1242 6.36458 11.1242C4.81285 11.1242 3.49971 10.5866 2.42517 9.51152C1.35063 8.43641 0.81307 7.12327 0.8125 5.5721C0.811931 4.02094 1.34949 2.7078 2.42517 1.63269C3.50085 0.557575 4.81399 0.0200195 6.36458 0.0200195C7.91518 0.0200195 9.22861 0.557575 10.3049 1.63269C11.3811 2.7078 11.9184 4.02094 11.9167 5.5721C11.9167 6.19849 11.817 6.78929 11.6177 7.3445C11.4184 7.89971 11.1479 8.39085 10.8063 8.81794L16.1875 14.1992L14.9917 15.395ZM6.36458 9.41585C7.43229 9.41585 8.33999 9.0423 9.08767 8.29519C9.83535 7.54808 10.2089 6.64038 10.2083 5.5721C10.2078 4.50383 9.83421 3.59642 9.08767 2.84987C8.34113 2.10333 7.43343 1.72949 6.36458 1.72835C5.29574 1.72721 4.38833 2.10105 3.64235 2.84987C2.89638 3.59869 2.52254 4.5061 2.52083 5.5721C2.51913 6.6381 2.89297 7.5458 3.64235 8.29519C4.39174 9.04457 5.29915 9.41813 6.36458 9.41585Z"
                fill="black"
              />
            </svg>
            <input
              className={css.searchMobileField}
              placeholder={intl.formatMessage({ id: 'SearchFormHero.mobileSearchPlaceholder' })}
              onFocus={() => setIsMobileSearchOpen(true)}
              readOnly
            />
          </div>
        </>
      ) : (
        <SearchBox
          isOpenAreaPanel={isOpenAreaPanel}
          setIsOpenAreaPanel={setIsOpenAreaPanel}
          isSmallScreen={isSmallScreen}
          intl={intl}
          handleSetKeyword={handleSetKeyword}
          keywordsValue={keywordsValue}
          setKeywordsValue={setKeywordsValue}
          setAreaValue={setAreaValue}
          handleOpenDatePanel={handleOpenDatePanel}
          startDateInputRef={startDateInputRef}
          dateLabel={dateLabel}
          handleChangeDateInput={handleChangeDateInput}
          handleResetDates={handleResetDates}
          isOpenPanel={isOpenPanel}
          endDateInputRef={endDateInputRef}
          focusedInput={focusedInput}
          handleChangeFocusInput={handleChangeFocusInput}
          handleSelectDates={handleSelectDates}
          whenValue={whenValue}
          setIsOpenPanel={setIsOpenPanel}
          handlePrevMonthClick={handlePrevMonthClick}
          handleNextMonthClick={handleNextMonthClick}
          isPrevDisabled={isPrevDisabled}
          isMobile={isMobile}
          numberOfMonths={numberOfMonths}
          isOutsideRange={isOutsideRange}
          currentMonth={currentMonth}
          handleLoadMoreMonths={handleLoadMoreMonths}
          isOpenLocationPanel={isOpenLocationPanel}
          setIsLocationPanel={setIsLocationPanel}
          countryLabel={countryLabel}
          setCountryLabel={setCountryLabel}
          setCountryValue={setCountryValue}
          handleSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default SearchFormHero;
