import moment from 'moment';

// The minimum Rental Period must be less than the number of days in the
// calendar plus 2 days.
export const createSearchParams = ({ whereValue, whenValue, keywordsValue,areaValue ,countryValue}) => {
  const { address, bounds } = whereValue?.selectedPlace || {};
  const { startDate = null, endDate = null } = whenValue || {};
  const startDateQuery = startDate && moment(startDate).format('YYYY-MM-DD');
  const endDateQuery = endDate && moment(endDate).format('YYYY-MM-DD');
  const dateQuery = startDateQuery && endDateQuery && `${startDateQuery},${endDateQuery}`;
  const rentalPeriod = startDate && endDate && moment(endDate).diff(moment(startDate), 'days') + 2;
  const pub_minimalRentalPeriod = rentalPeriod && `,${rentalPeriod}`;

  const pub_purpose = areaValue ? `has_all:${areaValue}`: null;

  const pub_country= countryValue ? `has_all:${countryValue}`: null;

  return {
    address,
    bounds,
    dates: dateQuery,
    ...(keywordsValue ? { keywords: keywordsValue } : {}),
    pub_minimalRentalPeriod,
    pub_purpose,
    pub_country,
  };
};
